
 import { defineComponent } from 'vue';
 import axios from "axios";
 import { Article } from '@/types';
 import setTitleToURL from '@/utils';

 export default defineComponent({
     name: 'Article',
     data() {
         return {
             articleId: this.$route.params.aid,
             article: {} as Article,
             loading: true
         }
     },
     methods: {
         getArticle() {
             axios.get(`https://jomo.news/api/articles/${this.articleId}`)
             .then((resp: any) => {
                 this.article = JSON.parse(JSON.stringify(resp.data))
                 this.loading = false
             }).catch((err: any) => {
                 console.error("Error getting article " + this.articleId + ":\n" + err)
             })
         },
         parseDate() {
             let spl = this.article.date.split("T")
             let date = spl[0]
             let fulltime = spl[1]
             let time = fulltime.split(":")
             return `${date} @ ${time[0]}:${time[1]}`
         }
     },
     mounted() {
         this.getArticle()
     }
 })
