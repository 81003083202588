<template>
    <div v-if="!this.loading" class="w-5/6 xl:w-1/2 mx-auto">
        <img class="mx-auto mb-4" v-if="this.article.image" :src="'https://jomo.news/api/' + this.article.image.formats.large.url"/>
        <div v-else class="h-64 w-full bg-blue-300 mb-4"/>
        <p class="text-4xl font-bold">{{ this.article.title }}</p>
        <div class="flex mb-4">
            <i class="w-1/2 text-gray-400 text-left">{{ this.article.author }}</i>
            <i class="w-1/2 text-gray-400 text-right">{{ parseDate() }}</i>
        </div>
        <div class="container"><p style="white-space: pre-wrap;" class="break-words prose md:prose xl:prose-2xl">{{ this.article.body }}</p></div>
    </div>
    <div v-else>
        <svg class="h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
    </div>
</template>

<script lang="ts">
 import { defineComponent } from 'vue';
 import axios from "axios";
 import { Article } from '@/types';
 import setTitleToURL from '@/utils';

 export default defineComponent({
     name: 'Article',
     data() {
         return {
             articleId: this.$route.params.aid,
             article: {} as Article,
             loading: true
         }
     },
     methods: {
         getArticle() {
             axios.get(`https://jomo.news/api/articles/${this.articleId}`)
             .then((resp: any) => {
                 this.article = JSON.parse(JSON.stringify(resp.data))
                 this.loading = false
             }).catch((err: any) => {
                 console.error("Error getting article " + this.articleId + ":\n" + err)
             })
         },
         parseDate() {
             let spl = this.article.date.split("T")
             let date = spl[0]
             let fulltime = spl[1]
             let time = fulltime.split(":")
             return `${date} @ ${time[0]}:${time[1]}`
         }
     },
     mounted() {
         this.getArticle()
     }
 })
</script>
